<template>
  <div class="layoutContainer">
    <div class="secondFloat">
      <el-form :inline="true" ref="formInline" :model="formInline" class="demo-form-inline">
        <el-form-item label="角色名" prop="rolename">
          <el-input v-model="formInline.rolename" placeholder="请输入角色名"></el-input>
        </el-form-item>
        <el-form-item label="编码" prop="code">
          <el-input v-model="formInline.code" placeholder="请输入编码"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" icon="el-icon-search">搜索</el-button>
          <el-button plain @click="reset('formInline')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableDetail">
      <el-button type="primary" icon="el-icon-plus"  @click="addHandle()">新增</el-button>
      <el-table style="width:100%" border :data="resultList" :header-cell-style="{'text-align':'center'}" :cell-style="{'text-align':'center'}">
        <el-table-column label="角色名" prop="name" width="272"></el-table-column>
        <el-table-column label="编码" prop="code" width="272"></el-table-column>
        <el-table-column label="层级" prop="level" width="275"></el-table-column>
        <el-table-column label="备注" prop="memo" width="265"></el-table-column>
        <el-table-column label="状态" prop="useFlag" width="265">
          <template slot-scope="{row}">
            <span v-if="row.useFlag === '1'" style="color:#009933">启用</span>
            <span v-if="row.useFlag === '0'" style="color:#cc0000">禁用</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="300">
          <template slot-scope="{row}">
            <el-button type="primary" size="mini" @click="handleEdit(row)">编辑</el-button>
            <el-button  type="primary" size="mini" @click="handleDelete(row)" style="background:#ff5722">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination v-show="total>0" :total="total" :page.sync="currentPage" :limit.sync="pagesize" @pagination="dataList"/>
      <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible" :before-close="handleCloseCreate">
        <el-form ref='dataForm' :model="dataForm" :rules="fromrules" label-position="center">
          <el-form-item label="角色名" prop="name" :label-width="formLabelWidth">
            <el-input v-model="dataForm.name" @input="e => dataForm.name= validForbid(e)" show-word-limit maxlength="30"   style="padding:0 48px 0 0"   placeholder="请输入角色名"></el-input>
          </el-form-item>
          <el-form-item label="编码" prop="code"  :label-width="formLabelWidth">
            <el-input v-model="dataForm.code" @input="e => dataForm.code= validForbid(e)" show-word-limit maxlength="20"   style="padding:0 48px 0 0"  placeholder="请输入角色名"></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="memo" :label-width="formLabelWidth">
            <el-input v-model="dataForm.memo" show-word-limit maxlength="50"   style="padding:0 48px 0 0"  placeholder="请输入备注"></el-input>
          </el-form-item>
          <el-form-item label="层级" prop="level" :label-width="formLabelWidth">
            <el-input style="width: 50% !important;overflow: hidden;" type="number" :min="1" :max="99"  @input="e => dataForm.level=parserNumber(e,1,99)" v-model="dataForm.level" placeholder="请输入等级"></el-input>
            <span style="display: block;color: #999;margin-left:10px;">数值越小，等级越高</span>
          </el-form-item>
          <el-form-item label="状态" prop="useFlag" :label-width="formLabelWidth">
            <el-switch v-model="whether"></el-switch>
          </el-form-item>
          <el-form-item :label-width="formLabelWidth">
            <el-button type="primary" @click="dialogStatus === 'add' ? addClick('dataForm') : editClick('dataForm')">
              立即提交
            </el-button>
            <el-button  v-if="dialogStatus === 'add'"   @click="resetForm('dataForm')">
              重置
            </el-button>
             <el-button  v-if="dialogStatus === 'edit'"  :disabled="true"   @click="resetForm('dataForm')">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { sysServerUrl, obj } from '@/assets/js/common.js'
import Pagination from '@/components/Pagination'
import { roleData, roleSearch, roleAdd, roleEdit, roleDel } from '@/api/api.js'
import axios from 'axios'
export default {
  name: 'layoutContainer',
  components: { Pagination },
  data () {
    return {
      formInline: {
        rolename: '',
        code: ''
      },
      dataForm: {
        name: '',
        code: '',
        level: 10,
        memo: '',
        useFlag: '1'
      },
      dialogFormVisible: false,
      formLabelWidth: '100px',
      dialogStatus: '',
      textMap: {
        edit: '编辑角色',
        add: '新增角色'
      },
      whether: false,
      resultList: [],
      pagesize: 10,
      currentPage: 1,
      total: 0,
      fromrules: {
        name: [{ required: true, message: '角色名不能为空', trigger: 'blur' }],
        code: [{ required: true, message: '编号不能为空', trigger: 'blur' }]
      }
    }
  },
  methods: {
    // 数据
    dataList () {
      var params = {
        page: this.currentPage,
        limit: this.pagesize,
        name: this.formInline.rolename,
        code: this.formInline.code
      }
      roleData(params).then(res => {
        this.total = res.data.total
        this.resultList = res.data.data
      })
      // axios.get(sysServerUrl + 'sys/role/list', {
      //   params: {
      //     page: this.currentPage,
      //     limit: this.pagesize
      //   }
      // }).then(res => {
      //   this.total = res.data.total
      //   this.resultList = res.data.data
      // })
    },
    // 搜索
    onSubmit () {
       this.currentPage=1
      var params ={
        page: this.currentPage,
        limit: this.pagesize,
        name: this.formInline.rolename,
        code: this.formInline.code
      }
      roleSearch(params).then(res => {
        this.total = res.data.total
        this.resultList = res.data.data
      })
    },
    handleCloseCreate () {
      this.dialogFormVisible = false
      this.$refs.dataForm.resetFields()
    },
    // 重置
    resetForm (dataForm) {
       this.resetTemp()
      if (this.$refs[dataForm].resetFields() !== undefined) {
        this.$refs[dataForm].resetFields()
      }
      this.whether=true
    },
    reset (formInline) {
      if (this.$refs[formInline].resetFields() !== undefined) {
        this.$refs[formInline].resetFields()

      }
      
      this.dataList()
    },
    resetTemp () {
      this.dataForm = {
        id: '',
        name: '',
        code: '',
        level: 10,
        memo: '',
        useFlag: '1'
      }
      this.whether=true
    },
    // 新增
    addHandle () {
      this.resetTemp()
      this.dialogStatus = 'add'
      this.dialogFormVisible = true
    },
    whetherState () {
      if (this.whether === false) {
        this.dataForm.useFlag = '0'
      } else {
        this.dataForm.useFlag = '1'
      }
    },
    addClick (dataForm) {
      this.$refs[dataForm].validate((valid) => {
        if (valid) {
          this.whetherState()
          var params = new URLSearchParams()
          params.append('name', this.dataForm.name)
          params.append('code', this.dataForm.code)
          params.append('level', this.dataForm.level)
          params.append('memo', this.dataForm.memo)
          params.append('useFlag', this.dataForm.useFlag)
          roleAdd(params).then(res => {
            if(res.data.code === 100){
              this.$message({
                message: res.data.msg,
                type: 'success'
              })
              this.dataList()
              this.dialogFormVisible = false
            }else{
              this.$message.error(res.data.msg)
            }
          })
        } else {
          this.$message.error('请完善角色信息')
        }
      })
    },
    // 编辑
    handleEdit (row) {
      this.resetTemp()
      this.dataForm = Object.assign({}, row)
      if(this.dataForm.useFlag=='1'){
        this.whether=true
      }else{
        this.whether=false
      }
      this.dialogStatus = 'edit'
      this.dialogFormVisible = true
    },
    editClick (dataForm) {
      this.$refs[dataForm].validate((valid) => {
        if (valid) {
          this.whetherState()
          var params = new URLSearchParams()
          params.append('id', this.dataForm.id)
          params.append('name', this.dataForm.name)
          params.append('code', this.dataForm.code)
          params.append('level', this.dataForm.level)
          params.append('memo', this.dataForm.memo)
          params.append('useFlag', this.dataForm.useFlag)
          roleEdit(params).then(res => {
            if(res.data.code === 100){
              this.$message({
                message: res.data.msg,
                type:'success'
              })
              this.dataList()
              this.dialogFormVisible = false
            }else {
              if(res.data.code === 404){
                this.$message.error('系统出现异常，更新失败')
              }else{
                this.$message.error(res.data.msg)
              }
            }
          })
        } else {
          this.$message.error('请完善角色信息')
        }
      })
    },
    // 删除
    handleDelete (row) {
      var _this= this
      this.$confirm('确定刪除 ' + row.name + row.code + '?', '信息提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        roleDel(row.id).then(res => {
          if(res.data.code === 100){
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            
             if(this.resultList!=null&&this.resultList.length==1){
              this.currentPage =this.currentPage-1
            }
            this.dataList()
          }
        })
      }).catch((error) => {
        _this.$message.error('删除失败')
      })
    }
  },
  mounted () {
    this.dataList()
    // obj.areaSize()
  }
}
</script>
